import React from 'react'
import { useHistory } from 'react-router'
import { usePaginationMutation } from 'hooks/pagination/pagination.hooks';
import PropTypes from "prop-types"
const AppContainer = ({ children }) => {
    const history = useHistory();
    const { setPaginationHooks } = usePaginationMutation();
    React.useEffect(() => {
        history.listen(() => {
            setPaginationHooks({
                name: "skip",
                value: 0
            })
        });
    }, [history,setPaginationHooks])
    return (
        <div className="">
            {children}
        </div>
    )

}
AppContainer.propTypes = {
    children: PropTypes.element
}
export default AppContainer