import React from 'react';
import FlexContainer from './FlexContainer';
import PropTypes from 'prop-types';
const Color = ({ color, name, label, value, textColor, onChange, checked }) => {
	const style = { backgroundColor: color, width: '150px' };
	return (
		<FlexContainer justityAround alignCenter className="p-2 m-2" style={style}>
			<input type="radio" name={name} value={value} onChange={onChange} checked={checked} />
			<span style={{ color: textColor }}>{label}</span>
		</FlexContainer>
	);
};
Color.propTypes = {
	name: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	textColor: PropTypes.string,
	checked: PropTypes.bool
};
export default Color;
