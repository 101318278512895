import gql from 'graphql-tag';
export const ADD_PRODUCT_MUTATION = gql`
	mutation addProduct($input: ProductInput) {
		addProduct(input: $input) {
			id
			name
		}
	}
`;
export const GET_ALL_PRODUCTS = gql`
	query getElasticProducts($limit: Int, $skip: Int, $filter: ProductQueryInput) {
		getProducts:getElasticProducts(limit: $limit, skip: $skip, filter: $filter) {
			nodes {
				id
				name
				colorDominant
				refProduct
				enabled
				active
				photoUrls {
					key
					photoUrl
				}
				isChecked
				subCategories {
					id
					name
					category{
						id
						name
					}
				}
				createdBy{
					florist{
						name
					}
				}
			}
			count
		}
	}
`;
export const ADD_PRODUCT_TO_MY_PRODUCTS = gql`
	mutation addProductToFlorist($input: ProductArrayIds!) {
		addProductToFlorist(input: $input)
	}
`;
export const REMOVE_PRODUCT_FROM_MY_PRODUCTS = gql`
	mutation removeProductToFlorist($id: ID!) {
		removeProductToFlorist(id: $id)
	}
`;
export const SAVE_PRODUCT_PRICE = gql`
	mutation saveProductPrice($input: ProductFloristInput!) {
		saveProductPrice(input: $input)
	}
`;
export const ENABLE_PRODUCT = gql`
mutation enableProduct($id:ID){
	enableProduct(id:$id)
		
}
`;
export const LOAD_PRODUCT_BY_ID = gql`
	query getProductById($productId: ID) {
		getProductById(productId: $productId) {
			id
			name
			description
			haveOneSize
			isChecked
			colorDominant
			ean
			ref
			
			createdBy {
				role
				isRequest
				refused
				refuseReason
				florist {
					name
				}
			}
			sizeProductPrice {
				priceIfOneSize
				size {
					small {
						price
						quantite
						disponible
					}
					medium {
						price
						quantite
						disponible
					}
					big {
						price
						quantite
						disponible
					}
				}
			}
			categories {
				id
				name
			}
			subCategories{
				id
				name
				category{
					id
					name
				}
			}
			theme {
				id
				name
			}
			photoUrls {
				photoUrl
				key
			}
		}
	}
`;
export const GET_ALL_CATEGORY = gql`
	query {
		getCategories {
			id
			name
			status
			photoUrl
		}
	}
`;
export const GET_ALL_THEME = gql`
	query {
		getThemes {
			id
			name
			photoUrl
			status
		}
	}
`;
export const UPDATE_PRODUCT_MUTATION=gql`
	mutation updateProduct($input:ProductInputUpdate){
		updateProduct(input:$input){
			id
		}
	}
`