import gql from 'graphql-tag';
const CommandFragment = {
  command: gql`
		fragment CommandFragment on Command{
			id
				num
				createdAt
				state
				client {
					firstName
					lastName
				}
				amount
				state
				deliveryMode
		}
	`
}
export const GET_COMMANDS_FLORIST = gql`
	query getCommandsFlorist($filter: CommandFilter, $skip: Int, $limit: Int) {
		getCommandsFlorist(filter: $filter, skip: $skip, limit: $limit) {
			nodes {
				...CommandFragment
			}
			count
		}
	}
	${CommandFragment.command}
`;
export const GET_COMMAND_BY_ID = gql`
  query getCommandById($id: String) {
    getCommandById(id: $id) {
      id
      amount
      num
	  state
	  createdAt
    message
	  deliveryMode
      products {
        product {
          id
          name
          photoUrls{
            photoUrl
          }
        }
        quantite
        price
        size
      }
      delivery {
        receptor
        tel
        deliveryAddress
        complementAddress
        staircase
        floor
        doorCode
        
        date
      }
      client {
        id
        firstName
        lastName
        tel
	    	fullName
      }
      apiResponseLivringInfos {
        job
        trackingUrl
        driver {
          lastName
          firstName
          phone
        }
      }
    }
  }
`