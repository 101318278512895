import React from "react";
import FlexContainer from "components/common/FlexContainer";
import PropTypes from "prop-types";
const Tags = ({ data }) => {
  return (
    <React.Fragment>
      {data &&
        data.map(cat => {
          return (
            <React.Fragment key={cat.id}>
				<FlexContainer column justifyStart alignStart className="mt-3">
				<span>{cat.name}</span>
				<FlexContainer row>
					{cat?.subCategories?.map(sub => {
					return <span className="tag tag-primary">{sub.name}</span>;
					})}
				</FlexContainer>
			  </FlexContainer>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};
Tags.propTypes = {
  data: PropTypes.array
};
export default Tags;