import React, { useState } from "react";
import { Modal } from "components";
import classNames from "classnames";
const withConfirmation = Component => props => {
    const [open, setOpen] = useState(false);
    const [theme, setTheme] = useState(null);
    const [callBack, setCallback] = useState(null);
    function confirm(theme, confCallback) {
        setOpen(!open);
        setCallback(() => confCallback);
        setTheme(theme);
    }
    function closeModal() {
        setOpen(false);
    }
    function onConfirm() {
        callBack();
        closeModal();
    }
    return (
        <React.Fragment>
            <Component {...props} confirmationDelete={confirm} />
            {open && (
                <div className={classNames(open && "modal-overlay")}>
                    <Modal
                        isOpen={open}
                        theme={theme}
                        close={closeModal}
                        confirm={onConfirm}
                    />
                </div>
            )}
        </React.Fragment>
    );
};
export default withConfirmation;
