export const COMMAND_STATUS = {
  PENDING: 'En attente',
  APPROVED: 'En cours',
  READY: 'Prête',
  REVOKED: "Rejété",
  DELIVERING: "En livraison",
  DELIVERED: "Livrée"
};
export const DeliveryModeLabel = {
  FREE: "Click&Collect",
  PROGRAMED: "Programmé",
  EXPRESS: "EXPRESS"
}
export const DeliveryMode = {
  FREE: "FREE",
  PROGRAMED: "PROGRAMED",
  EXPRESS: "EXPRESS"
}
export const MomentFormat = {
  DATE_HOURS: 'DD/MM/YYYY HH:mm',
  DATE: 'DD/MM/YYYY',
  TIME: 'HH:mm'
}
export const Routes = {
  login: "/login",
  home: "/home",
  commands: "/commands",
  products: "/products",
  myProducts: "/my-products",
  detailProduct: "/product-details",
  requests: "/product-requests",
  addRequest: "request-add-product",
  detailRequest: "product-request-detail"
}
export const DominantColors = [
  {
    name: "colorDominant",
    label: "JAUNE",
    value: "yellow",
    color: "#FFF033",
    textColor: "#000",
  },
  {
    name: "colorDominant",
    label: "ROUGE",
    value: "red",
    color: "red",
    textColor: "white",
  },
  {
    name: "colorDominant",
    label: "BLANC",
    value: "white",
    color: "#fff",
    textColor: "#000",
  },
  {
    name: "colorDominant",
    label: "ROSE",
    value: "pink",
    color: "#FFC0CB",
    textColor: "#000",
  },
  {
    name: "colorDominant",
    label: "VIOLET",
    value: "purple",
    color: "purple",
    textColor: "white",
  },
  {
    name: "colorDominant",
    label: "ORANGE",
    value: "orange",
    color: "#FFA500",
    textColor: "white",
  }
];
