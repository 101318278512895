import React, {Component} from 'react';
import EmptyResult from 'components/common/EmptyResult';
import { Spinner } from 'components';

export default class GraphqlResult extends Component {
  render() {
    const {loading, errors, refetch, style, data, children} = this.props;

    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          
            <Spinner/>
         
        </div>
      );
    }
   /* if (errors) {
      return (
        <div className="d-flex">
          <span className="errors">{errors.toString()}</span>
        </div>
      );
    }*/
    if (data && data.length == 0) {
      return <EmptyResult/>
    }

    return children
  }
}

