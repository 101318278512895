import { useQuery, useMutation } from "react-apollo";
import { GET_ALL_REQUESTS } from "graphql/tags/requests.tag";
import { LOAD_PAGINATION_INFO, LOAD_REFUSE_REASON_TEXT } from "graphql/query.local";
import { GET_ALL_CATEGORY, GET_ALL_THEME } from "graphql/tags";
import { array } from "prop-types";
import { SET_TEXT_REASON } from "graphql/mutation.local";

export const useRequestsQuery = filter => {
    const {
        data: {
            loadPagination: { skip, limit }
        }
    } = useQuery(LOAD_PAGINATION_INFO);
    const { data, loading } = useQuery(GET_ALL_REQUESTS, {
        variables: {
            limit,
            skip,
            filter
        }
    });
    return {
        requests: data && data.getRequests && data.getRequests.nodes,
        count: data && data.getRequests && data.getRequests.count,
        loading
    };
};
export const useValidationForm = data => {
    const errors = [];
    let errorsValidation = {};
    Object.keys(data).forEach(
        key => {
            if (typeof data[key] == array && data[key].length == 0) {
                errors.push({ [key]: `champ obligatoire` })
                errorsValidation[key] = 'champ obligatoire'
            }
            if (data[key] == "" || data[key] == null) {
                errors.push({ [key]: `champ obligatoire` })
                errorsValidation[key] = 'champ obligatoire'
            }
        }
    );
    return { errors: errors.length > 0 ? errorsValidation : null };
};
export const useQueryCategory = () => {
    const { data: dataCategory, loading: loadingCatgory } = useQuery(GET_ALL_CATEGORY);
    return { dataCategory, loadingCatgory }

}
export const useQueryTheme = () => {
    const { data: dataTheme, loading: loadingTheme } = useQuery(GET_ALL_THEME);
    return { dataTheme, loadingTheme }
}
export const useRefuseQuery = () => {
    const { data: { refuseReason: { text } } } = useQuery(LOAD_REFUSE_REASON_TEXT);
    return { text }
}
export const useMutationReasonRefuse = () => {
    const [setReasonRefuseText] = useMutation(SET_TEXT_REASON);
    function setTextRefuse(text) {
        setReasonRefuseText({
            variables: {
                text
            }
        })
    }
    return { setTextRefuse }
}