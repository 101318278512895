import React from 'react'
import { Image } from 'react-bootstrap'
import { Icon } from 'components'
import PropTypes from 'prop-types'
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import NotificationList from './notification/NotificationList'
import { useNotificationQuery } from 'hooks/notification/notification.hooks'
import Token from 'helpers/Token'
import { useHistory } from 'react-router'
import { Routes } from 'helpers/Contants'

const Badge = ({ value }) => (value && value > 0 ? <span className='notification-badge'>{value}</span> : null)
Badge.propTypes = {
  value: PropTypes.number
}
const NavBar = (props) => {
  const history = useHistory()
  const { active } = props
  const { inReaded } = useNotificationQuery()

  React.useEffect(() => {}, [null])
  function logout () {
    localStorage.removeItem('token')
    history.push(Routes.login)
  }

  return (
    <div id='navbar'>
      <div className='d-flex align-items-center Resp'>
        <Image src='/images/logo-rose.png' className='logo-navbar' />
        <span className='label label-pink ml-2'>{` > ${active}`}</span>
        <span className='DispNone'>{Token.getRole()}</span>
      </div>
      <div className='right-icon d-flex align-items-center justify-content-between'>
        <Icon name='message' className='icon-navbar' />
        <div className='cursor-pointer'>
          <UncontrolledDropdown nav direction='down'>
            <DropdownToggle nav className='no-padding'>
              <Icon name='bell' className='icon-navbar' />
              <Badge value={inReaded} />
            </DropdownToggle>
            <DropdownMenu>
              <NotificationList />
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <UncontrolledDropdown nav direction='up'>
          <DropdownToggle nav className='no-padding'>
            <Image src='/images/user.png' className='img-profile' />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <div onClick={logout}>
                {' '}
                <i className='fa fa-lock' /> Logout{' '}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}

export default NavBar
