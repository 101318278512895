import React, { Component } from 'react'
import ReactLoading from "react-loading"
export default class Spinner extends Component {
    render() {
        const {color,height,width} =this.props;
        return (
            <ReactLoading  type={"spinningBubbles"} color={"red"} height={'2em'} width={'2em'} />
        )
    }
}
