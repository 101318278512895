import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import classNames from 'classnames';

const Standard = ({ loading, disabled = false, className, title, loadingIndicatorSize, justityBetween,
  justifyAround,
  justifyStart,
  justifyEnd,
  justifyCenter,
  alignCenter,
  alignStart,
  alignEnd,
  success,
  cancel,
  red,
  noBorder,
  wrap, ...others }) => {

  const style = classNames("d-flex", "btn btn-secondary",
    justifyCenter && "justify-content-center",
    justityBetween && 'justify-content-between',
    justifyAround && 'justify-content-around',
    justifyStart && 'justify-content-start',
    justifyEnd && 'justify-content-end',
    justifyCenter && 'justify-content-center',
    alignCenter && 'align-items-center',
    alignStart && 'align-items-start',
    alignEnd && 'align-items-end',
    cancel && "btn_cancel ",
    success && "btn_green",
    red && "errors",
    noBorder && "border-0",
    wrap && 'flex-wrap', className);

  return (
    <Button
      className={style}
      disabled={disabled}
      {...others}
    >
      {loading && <PulseLoader size={loadingIndicatorSize | 5} color="#fff" />}
      <span>{title}</span>{' '}
    </Button>
  );
};
Standard.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  loadingIndicatorSize: PropTypes.number,
  justifyCenter: PropTypes.bool,
  justityBetween: PropTypes.bool,
  justifyAround: PropTypes.bool,
  justifyStart: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignStart: PropTypes.bool,
  alignEnd: PropTypes.bool,
  wrap: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  success: PropTypes.bool,
  cancel: PropTypes.bool,
  red: PropTypes.bool,
  noBorder: PropTypes.bool
};
export default Standard;