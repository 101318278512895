import gql from "graphql-tag";
export const SET_PAGINATION = gql`
	mutation setPagination($name:String,$value:Int) {
		setPagination(name:$name,value:$value) @client{
            id
        }
	}
`;
export const SET_PRODUCT_TO_ADD = gql`
	mutation setProduct($id:String) {
		setProduct(id:$id) @client{
            id
        }
	}
`;
export const REMOVE_PRODUCT_TO_ADD = gql`
	mutation removeProduct($id:String) {
		removeProduct(id:$id) @client{
            id
        }
	}
`;
export const SET_PRODUCT_FILTER_CATALOG = gql`
mutation setProductFilter($myProducts:Boolean){
	setProductFilter(myProducts:$myProducts) @client{
		id
	}
}
`;
export const SET_PRODUCT_FILTER_CATAEGORY = gql`
mutation setProductFilterCategory($input:InputQueryFilter){
	setProductFilterCategory(input:$input) @client{
		id
	}
}
`;
export const SET_TEXT_REASON = gql`
	mutation setReasonRefuseText($text:String) {
		setReasonRefuseText(text:$text) @client{
            id
        }
	}
`;
