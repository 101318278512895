import { useQuery} from "react-apollo";
import {
    GET_ALL_NOTIFICATIONS,
    NOTIFICATION_ADDEDD
} from "graphql/tags/notification.tag";
import Token from "helpers/Token";

export const useNotificationQuery = filter => {

    const { data, loading, subscribeToMore } = useQuery(GET_ALL_NOTIFICATIONS);
    function subscribeToMoreNotification() {
        subscribeToMore({
            document: NOTIFICATION_ADDEDD,
            variables: { id: Token.getId() },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newFeedItem = subscriptionData.data.notificationAdded;
                return {
                    ...prev,
                    getNotifications: {
                        ...prev.getNotifications,
                        count: 0,
                        nodes: [newFeedItem, ...prev.getNotifications.nodes]
                    }
                };
            }
        });
    }
    const inReaded = data && data.getNotifications && data.getNotifications.nodes && data.getNotifications.nodes.map(current => current.readed === false).length
    return {
        notifications: data && data.getNotifications && data.getNotifications.nodes,
        loading,
        count: data && data.getNotifications && data.getNotifications.count,
        loading,
        subscribeToMoreNotification,
        inReaded
    };
};
