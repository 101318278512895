import { LOAD_PAGINATION_INFO, LOAD_PRODUCTS_TO_ADD, LOAD_PRODUCT_FILTER, LOAD_REFUSE_REASON_TEXT } from "./query.local";

export default {
    Query: {
        pagination: (_, { }, { getCacheKey }) => {
            return getCacheKey({ __typename: "Pagination" });
        },
        productsChecked: (_, { }, { getCacheKey }) => {
            return getCacheKey({ __typename: "ProductsChecked" });
        },
        productFilter: (_, { }, { getCacheKey }) => {
            return getCacheKey({ __typename: "ProductFilter" })
        }
    },
    Mutation: {
        setPagination: (_, { name, value }, { cache }) => {
            let data = cache.readQuery({ query: LOAD_PAGINATION_INFO });

            data = {
                ...data,
                loadPagination: {
                    ...data.loadPagination,
                    [name]: value
                }
            };

            cache.writeQuery({ query: LOAD_PAGINATION_INFO, data });

        },
        setProduct: (_, { id }, { cache }) => {
            let data = cache.readQuery({ query: LOAD_PRODUCTS_TO_ADD });
            if (!!!data.productsChecked.products.includes(id)) {
                data = {
                    ...data,
                    productsChecked: {
                        ...data.productsChecked,
                        products: [...data.productsChecked.products, id]
                    }
                };
            } else {
                data = {
                    ...data,
                    productsChecked: {
                        ...data.productsChecked,
                        products: [...data.productsChecked.products.filter(i => i !== id)]
                    }
                };
            }

            cache.writeQuery({ query: LOAD_PRODUCTS_TO_ADD, data });
        },
        removeProduct: (_, { id }, { cache }) => {
            let data = cache.readQuery({ query: LOAD_PRODUCTS_TO_ADD });
            data = {
                ...data,
                productsChecked: {
                    ...data.productsChecked,
                    products: [...data.productsChecked.products.filter(i => i !== id)]
                }
            };
            cache.writeQuery({ query: LOAD_PRODUCTS_TO_ADD, data });
        },
        setProductFilter: (_, { myProducts }, { cache }) => {

            let data = cache.readQuery({ query: LOAD_PRODUCT_FILTER });

            data = {
                ...data,
                productFilter: {
                    ...data.productFilter,
                    myProducts
                }
            }
            cache.writeQuery({ query: LOAD_PRODUCT_FILTER, data })
        },
        setProductFilterCategory: (_, { input }, { cache }) => {

            let data = cache.readQuery({ query: LOAD_PRODUCT_FILTER });

            data = {
                ...data,
                productFilter: {
                    
                    ...data.productFilter,
                    ...input,
                    
                }
            }
     
            cache.writeQuery({ query: LOAD_PRODUCT_FILTER, data })
        },
        setReasonRefuseText: (_, { text }, { cache }) => {
            let data = cache.readQuery({ query: LOAD_REFUSE_REASON_TEXT });
            data = {
                ...data,
                refuseReason: {
                    ...data.refuseReason,
                    text
                }
            };

            cache.writeQuery({ query: LOAD_REFUSE_REASON_TEXT, data });
        }
    }
};
