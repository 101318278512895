import React from 'react'
import { Card } from 'react-bootstrap';
import PropTypes from "prop-types";
import classNames from "classnames";
const MyCard = ({ children, className }) => {

    const style = classNames("card no-padding no-margin", className)
    return <Card className={style}>
        {children}
    </Card>
}
MyCard.propTypes = {
    children: PropTypes.any
}
export default MyCard;