import React, { Component } from "react";
import { Icon } from "components";
import classNames from "classnames";
import { menu } from "./helpers/HsideBar";
import PropTypes from "prop-types";
import Token from "helpers/Token";

const MenuItem = ({ icon, name, route, open, active, handleRoute }) => {
	function navigate() {
		handleRoute({ name, route });
	}
	return (
		<div
			onClick={navigate}
			className={classNames(
				"d-flex",
				"cursor-pointer",
				"align-items-center",
				"p-2",
				!open && "justify-content-center",
				active === name && "active-menu"
			)}
		>
			<Icon name={icon} className="sidebar-icon" />
			{open && (
				<span className="sidebar-menu-title label label-grey ml-2">{name}</span>
			)}
		</div>
	);
};

MenuItem.propTypes = {
	icon: PropTypes.string,
	name: PropTypes.string,
	route: PropTypes.string,
	open: PropTypes.bool,
	active: PropTypes.string,
	handleRoute: PropTypes.func,
	item: PropTypes.array
};

class SideBar extends Component {
	static propTypes = {
		active: PropTypes.string,
		open: PropTypes.bool,
		handleSideBar: PropTypes.func,
		handleRoute: PropTypes.func
	};

	render() {
		const { active, open, handleRoute, handleSideBar } = this.props;
		return (
			<div
				id="sidebar"
				className={classNames(
					open ? "sidebar-full-width" : "sidebar-min-width"
				)}
			>
				<div
					onClick={handleSideBar}
					className={classNames(
						"d-flex",
						"cursor-pointer",
						"align-items-center",
						"m-2",
						"justify-content-center"
					)}
				>
					<Icon name="menu" className="sidebar-icon" />
				</div>

				<div className="sidebar-menu-container">
					{menu.map(item => (
						item.role.includes(Token.getRole()) &&
						<MenuItem
							key={item.route}
							{...item}
							open={open}
							active={active}
							handleRoute={handleRoute}
						/>
					))}
				</div>
			</div>
		);
	}
}

export default SideBar;
