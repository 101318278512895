import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Input, Button, Checkbox, FlexContainer } from 'components';
import { Row, Col, Form } from 'react-bootstrap';
import { FormGroup, Label, FormText, Media } from 'reactstrap';
import { useMutation } from 'react-apollo';
import { ADD_PRODUCT_MUTATION } from 'graphql/tags';
import { makeId, formatToMultiSelectData } from 'helpers/function';
import { DominantColors, Routes } from 'helpers/Contants';
import Color from 'components/common/Color';
import classNames from 'classnames';
import lodash from "lodash";
import { Multiselect } from "multiselect-react-dropdown";
import { useValidationForm, useQueryCategory, useQueryTheme } from 'hooks/request/request.hooks';
import { useQueryCategoryWihSubCategories } from 'hooks/category/category.hooks';
const INITIAL_STATE = {
	name: '',
	ref: '',
	ean: null,
	files: [],
	category: null,
	description: '',
	haveOneSize: false,
	theme: [],
	colorDominant: ''
};
const RADIO_VALUES = {
	one: true,
	multi: false
};
const DropImage = ({ src, removeImage }) => (
	<div className="boxGal" key={src.key}>
		<div className="close-btn cursor-pointer" onClick={() => removeImage(src.key)}>
			x
		</div>
		<img src={src.file} />
	</div>
);
DropImage.propTypes={
	src:PropTypes.any,
	removeImage:PropTypes.func
}
const RequestProductAdd = ({ history }) => {
	const [ state, setState ] = useState(INITIAL_STATE);
	const {data}=useQueryCategoryWihSubCategories();
	const [selectedSubCategories,setSelectedSubCategories]=useState([]);
	const [ uris, setUris ] = useState([]);
	const [ errors, setErrors ] = useState(null);
	const { dataCategory } = useQueryCategory();
	const { dataTheme } = useQueryTheme();
	const [ addProduct ] = useMutation(ADD_PRODUCT_MUTATION);
	function _handleChangeText(e) {
		setState({ ...state, [e.target.name]: e.target.value });
	}
	const _handleSubmit = e => {
	
		e.preventDefault();
		const { name, ref, description, ean, haveOneSize, theme, files, category, colorDominant } = state;
		setErrors(null);
		const { errors: errorsValidation } = useValidationForm({
			name,
			description,
			files,
		});
		if (errorsValidation) {
			setErrors(errorsValidation);
			console.log(errorsValidation);
			return null;
		}
			
		
		addProduct({
			variables: {
				input: {
					name,
					ref,
					description,
					ean,
					subCategories:selectedSubCategories?.map(cat=>cat?.subCategoryId),
					categories:lodash.uniq(selectedSubCategories?.map(cat=>cat?.categoryId)),
					haveOneSize: !!haveOneSize,
					theme: Object.values([ ...theme.map(th => th.id) ]),
					files,
					colorDominant
				}
			}
		}).then(() => {
			history.push(Routes.products);
		});

		return null;
	};
	const _handleImageChange = ({ target: { files } }) => {
		let key = makeId(10);
		console.log(key);
		setState({ ...state, files: [ ...state.files, { key, file: files[0] } ] });
		const reader = new FileReader();
		reader.readAsDataURL(files[0]);
		reader.onloadend = () => {
			setUris([ ...uris, { key, file: reader.result } ]);
		};
	};
	function removeImage(key) {
		setUris([ ...uris.filter(img => img.key !== key) ]);
		setState({ ...state, files: [ ...state.files.filter(img => img.key !== key) ] });
	}
	const _isThemeChecked = (id, array) => {
		return array.some(theme => theme.id == id);
	};

	const _handleChangeTheme = (e, th) => {
		if (e.target.checked) {
			setState({ ...state, theme: [ ...state.theme, th ] });
		} else {
			setState({ ...state, theme: [ ...state.theme.filter(t => t.id !== th.id) ] });
		}
	};
	function goBack() {
		history.push(Routes.requests);
	}
	function _handleSelectCategorie(event){
		setSelectedSubCategories(event)
	}
	function _handleRemoveCategorie(event){
		setSelectedSubCategories(event);
	}

	const { name, description, ref, haveOneSize, theme } = state;

	return (
		<div className="commands-container flex-wrap request">
			<Card className="no-margin no-padding">
				<div className="p-4 classResp">
					<Form onSubmit={_handleSubmit}>
						<Row>
							<Col xs={12} md={6}>
								<span className="title1"> Ajouter un Produit </span>
							</Col>
							<Col xs={12} md={6} className="BoxFlex">
								<Button title="Annuler" className="btn_cancel" type="button" onClick={goBack} />
								<Button title="Envoyer" className="btn_green" type="submit" />
							</Col>
						</Row>

						<div className="mt-3">
							<Row>
								<Col xs={12} md={6}>
									<FormGroup check>
										<Label className="labClass">Catégorie</Label>
										<Multiselect
											options={formatToMultiSelectData(data)}
											displayValue="key"
											groupBy="cat"
											showCheckbox={true}
											onSelect={_handleSelectCategorie}
											onRemove={_handleRemoveCategorie}
									/>
										<Input
											className="inputClass hidden"
											type="select"
											name="category"
											id="exampleSelect"
											invalid={errors && errors.category && true}
											onChange={_handleChangeText}
										>
											<React.Fragment>
												<option value={null}>Selectionner une catégorie</option>
												{dataCategory &&
													dataCategory.getCategories &&
													dataCategory.getCategories.map(cat => (
														<option key={cat.id} value={cat.id}>
															{cat.name}
														</option>
													))}
											</React.Fragment>
										</Input>
									</FormGroup>
									
									<FormGroup check>
										<Label className="labClass">Nom</Label>
										<Input
											invalid={errors && errors.name && true}
											className="inputClass"
											name="name"
											onChange={_handleChangeText}
											value={name}
										/>
									</FormGroup>

									<FormGroup check>
										<Label className="labClass">Description</Label>
										<Input
											className="inputClass"
											type="textarea"
											id="exampleText"
											name="description"
											invalid={errors && errors.description && true}
											onChange={_handleChangeText}
											value={description}
										/>
									</FormGroup>
									<FormGroup check>
										<Label className="labClass">Dimention</Label>
										<div className="boxFlex">
											<FormGroup className="m-2" check>
												<Label check>
													<Input
														type="radio"
														name="haveOneSize"
														value={RADIO_VALUES.one}
														onChange={_handleChangeText}
														checked={String(haveOneSize) == 'true'}
													/>
													Uni dimension
												</Label>
											</FormGroup>

											<FormGroup className="m-2" check>
												<Label check>
													<Input
														type="radio"
														name="haveOneSize"
														checked={String(haveOneSize) == 'false'}
														value={RADIO_VALUES.multi}
														onChange={_handleChangeText}
													/>
													Multi dimension
												</Label>
											</FormGroup>
										</div>
									</FormGroup>
									<FormGroup check>
										<Label className="labClass">Occasions</Label>
										<FlexContainer className="NoPad" wrap row>
											{dataTheme &&
												dataTheme.getThemes &&
												dataTheme.getThemes.map(theme => (
													<Checkbox
														key={theme.id}
														label={theme.name}
														isChecked={_isThemeChecked(theme.id, state.theme)}
														onChange={e => _handleChangeTheme(e, theme)}
													/>
												))}
										</FlexContainer>
									</FormGroup>
								</Col>
								{/* second column */}

								<Col xs={12} md={6}>
									<FormGroup className="dashedBox">
										<img src="images/camera.png" alt="Generic placeholder image" />
										<FormText color="muted"> Glissez et déposez une image ou </FormText>
										<div className="BoxUp1">
											<Input type="file" name="file" id="exampleFile" onChange={_handleImageChange} />
											<div className={classNames('BoxUp', errors && errors.files && 'border border-danger')}>
												Choisir un fichier
											</div>
										</div>
									</FormGroup>

									<div className="gal">
										{uris.map(src => <DropImage src={src} key={src.key} removeImage={removeImage} />)}
									</div>
									<FormGroup>
										<Label className="labClass">Code ean</Label>
										<Input
											invalid={errors && errors.ean && true}
											className="inputClass"
											type="text"
											name="ean"
											onChange={_handleChangeText}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="labClass">Référence fournisseur</Label>
										<Input
											invalid={errors && errors.ref && true}
											className="inputClass"
											type="text"
											name="ref"
											onChange={_handleChangeText}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="labClass">COULEUR</Label>
										<FlexContainer wrap>
											{DominantColors.map(item => (
												<Color
													{...item}
													key={makeId(10)}
													onChange={_handleChangeText}
													checked={state.colorDominant == item.value}
												/>
											))}
										</FlexContainer>
									</FormGroup>
								</Col>
							</Row>
						</div>
					</Form>
				</div>
			</Card>
		</div>
	);
};

RequestProductAdd.propTypes = {
	history: PropTypes.any
};

export default RequestProductAdd;
