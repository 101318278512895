import React from 'react';
import { ApolloProvider } from 'react-apollo';
import client from 'config/apolloClient';
import { Spinner } from 'components';
import { hot } from "react-hot-loader/root";

import Router from 'routes';

function App() {
  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
}

export default hot(App);
