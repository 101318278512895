import React from 'react';
import { FlexContainer } from 'components';
import { useNotificationQuery, useNotificationSubscription } from 'hooks/notification/notification.hooks';
import GraphqlResult from 'graphql/components/GraphqlResult';
import { makeId } from 'helpers/function';
import moment from 'moment';
import { MomentFormat } from 'helpers/Contants';
const NotificationItem = ({ message, createdAt }) => (
	<div className="notification-item">
		<FlexContainer justityBetween alignEnd className="p-2">
			<div>{message}</div>
			<span>{moment(parseFloat(createdAt)).format(MomentFormat.TIME)}</span>
		</FlexContainer>
	</div>
);

const NotificationList = () => {
    const { notifications, loading ,subscribeToMoreNotification} = useNotificationQuery();
    React.useEffect(()=>{
        subscribeToMoreNotification()
    },[null])

	return (
		<div className="notification-container">
			<section className="notification-header">
				<span>Notification</span>
			</section>
			<section className="notification-body">
				<GraphqlResult loading={loading}>
					{notifications && notifications.map(notif => <NotificationItem key={makeId(10)} {...notif} />)}
				</GraphqlResult>
			</section>
			<section className="notification-footer">
				<span>Voir plus</span>
			</section>
		</div>
	);
};
export default NotificationList;
