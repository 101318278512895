import gql from 'graphql-tag';

export const GET_ALL_REQUESTS = gql`
	query getRequests($limit: Int, $skip: Int, $filter: RequestFilterInput) {
		getRequests(limit: $limit, skip: $skip, filter: $filter) {
			nodes {
				id
                name
                photoUrls{
                    photoUrl
                }
				createdBy {
                    role
                    isRequest
                    refused
				
					
					florist {
						name
					}
				}
				createdAt
			}
			count
		}
	}
`;
export const HANDLE_REQUEST = gql`
mutation handleRequest($input: RequestInput, $status: Boolean!){
    handleRequest(input:$input,status:$status)
}
`;
