import { ApolloClient } from "apollo-boost";
import { ApolloLink , split } from "apollo-link";
import { RetryLink } from "apollo-link-retry";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";

import { getMainDefinition } from "apollo-utilities";
import { withClientState } from "apollo-link-state";
import { BASE_URL, WS_URL } from "./constants";
import { resolvers, typeDefs } from "../graphql";
import Alert from "helpers/Alert";
const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case "Pagination":
        return "Pagination";
      case "Product":
        return Math.random()
          .toString(36)
          .substring(7);
      default:
        return object.id || null;
    }
  }
});

const uploadLink = createUploadLink({
  uri: BASE_URL + "/graphql"
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message) {
        const [f, msg, x] = message.split("\"");
        Alert.error(msg);
      }

      console.dir(message);
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const stateLink = withClientState({
  defaults: {
    loadPagination: {
      skip: 0,
      limit: 10,
      __typename: "Pagination"
    },
    refuseReason: {
      text: "",
      __typename: "RefuseReason"
    },
    productsChecked: {
      products: [],
      __typename: "ProductsChecked"
    },
    productFilter: {
      myProducts: false,
      category: null,
      __typename: "ProductFilter"
    }
  },
  resolvers,
  typeDefs,
  cache
});

const wsLink = new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true,
    connectionParams: async () => ({
      token: localStorage.getItem("token")
    })
  }
});

const authMiddleware = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem("token") // token.replace(new RegExp('"', "g"), "")
    }
  };
});

const retryLink = new RetryLink();

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind == "OperationDefinition" && operation == "subscription";
  },
  wsLink,
  uploadLink,
  stateLink
);

const client = new ApolloClient({
  link: ApolloLink.from([authMiddleware, errorLink, retryLink, link]),

  cache,
  resolvers,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore"
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    },
    mutate: {
      errorPolicy: "all"
    }
  }
});

export default client;
