import React, { Component } from "react";
import { Input as Inp } from "reactstrap";
import { FormGroup } from "react-bootstrap";
class Input extends Component {
  render() {
    const { type, icon, ...others } = this.props;
    return <Inp type={type} {...others} />;
  }
}

export default Input;
