import gql from 'graphql-tag';
export const GET_ALL_NOTIFICATIONS = gql`
	query getNotifications($limit: Int, $skip: Int) {
		getNotifications(limit: $limit, skip: $skip) {
			nodes {
				id
                message
				readed
				createdAt
			}
			count
		}
	}
`;
export const NOTIFICATION_ADDEDD = gql`
	subscription notificationAdded($id:ID!){
		notificationAdded(id:$id){
			id
			message
			readed
			createdAt
		}
	}
`