export default `
input commandInput {
  id:ID!
}
type Pagination{
  limit:Int
  skip:Int
}
input PaginationInput{
  limit:Int
  skip:Int
}
input InputQueryFilter{
  category:String
  value:String
}
type ProductsChecked{
  products:[String]
}
type ProductFilter{
  myProducts:Boolean
  category:String
}
`