import React, { Component } from 'react';
import { NavBar, SideBar } from 'components';
import { withRouter } from 'react-router';
import { checkToken } from 'api/auth';
import AppContainer from 'containers/AppContainer';
class PrivateRoute extends Component {
	state = {
		open: false,
		active: 'Accueil'
	};
	handleSideBar = () => this.setState({ open: !this.state.open });
	handleRoute = ({ route, name }) => {
		this.setState({ active: name }, () => {
			this.props.history.replace(route);
		});
	};
	componentDidMount() {
		const token = localStorage.getItem("token");
		if (token) {
			checkToken(token).then(response => {

			}).catch(err => {
				this.props.history.push("login");
			})
		} else {
			this.props.history.push("login");
		}



	}

	render() {
		return (
			<div className="private-container">
				<SideBar
					open={this.state.open}
					active={this.state.active}
					handleSideBar={this.handleSideBar}
					handleRoute={this.handleRoute}
				/>
				<main className="main">
					<NavBar active={this.state.active} />
					<AppContainer >{this.props.children}</AppContainer>
				</main>
			</div>
		);
	}
}
export default withRouter(PrivateRoute);
