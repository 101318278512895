import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { Spinner, FlexContainer } from 'components'
import AddProduct from 'containers/Products/RequestAddProduct'
import { isAdmin } from 'helpers/function'
import Token from 'helpers/Token'

const Login = React.lazy(() => import('containers/Login'))
const Home = React.lazy(() => import('containers/Home'))
const Commands = React.lazy(() => import('containers/commands'))
const Products = React.lazy(() => import('containers/Products/'))
const MyProducts = React.lazy(() => import('containers/Products/MyProducts'))
const CommandDetail = React.lazy(() => import('containers/commands/CommandDetail'))
const ProductDetails = React.lazy(() => import('containers/Products/ProductDetails'))
const RequestAddProduct = React.lazy(() => import('containers/Products/RequestAddProduct'))
const ProductRequests = React.lazy(() => import('containers/Requests/ProductRequests'))
const Occasion = React.lazy(() => import('containers/Occasion'))
const Category = React.lazy(() => import('containers/Category'))
const Profile = React.lazy(() => import('containers/Profile'))
const ProduitAdmin = React.lazy(() => import('containers/Products/index.admin'))
const ProductRequestDetails = React.lazy(() => import('containers/Requests/ProductRequestDetails'))
const SubCategory = React.lazy(() => import('containers/Category/SubCategory'))
const EditProduct =React.lazy(()=> import("containers/Products/EditProduct"))
const publicRoutes = [
  {
    path: '/login',
    component: Login,
    exact: true
  }
];
const privateRoutes = [
  {
    path: '/home',
    component: Home,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/commands',
    component: Commands,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/my-products',
    component: MyProducts,
    exact: true,
    role: ['FLORIST']
  },
  {
    path: '/occasion',
    component: Occasion,
    exact: true,
    role: ['ADMIN']
  },
  {
    path: '/product-list',
    component: Products,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/product-requests',
    component: ProductRequests,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/product-request-detail/:id',
    component: ProductRequestDetails,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/category',
    component: Category,
    exact: true,
    role: ['ADMIN']
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    role: ["FLORIST"]
  },
  {
    path: '/subcategory',
    component: SubCategory,
    exact: true,
    role: ['ADMIN']
  },
  {
    path: '/account',
    component: Home,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/products',
    component: isAdmin() ? ProduitAdmin : Products,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/add-product',
    component: AddProduct,
    exact: true,
    role:["ADMIN","FLORIST"]
  },
  {
    path: "/edit-product/:id",
    component: EditProduct,
    exact: true,
    role:["ADMIN","FLORIST"]
  },

  {
    path: '/gallery',
    component: Home,
    exact: true,
    role: ['FLORIST']
  },
  {
    path: '/chat',
    component: Home,
    exact: true,
    role: ['FLORIST']
  },
  {
    path: '/help',
    component: Home,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/command/:id',
    component: CommandDetail,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/product-details/:id',
    component: ProductDetails,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  },
  {
    path: '/request-add-product',
    component: RequestAddProduct,
    exact: true,
    role: ['ADMIN', 'FLORIST']
  }
]

export default class Router extends React.Component {
  render () {
    return (
      <React.Suspense
        fallback={
          <FlexContainer row justifyCenter alignCenter>
            {" "}
            <Spinner />
          </FlexContainer>
        }>
        <HashRouter>
          <Switch>
            {publicRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            <PrivateRoute>
              <Switch>
                {privateRoutes.map(
                  route =>
                    route &&
                    route.role.includes(Token.getRole()) && (
                      <Route key={route.path} {...route} />
                    )
                )}
              </Switch>
            </PrivateRoute>
          </Switch>
        </HashRouter>
      </React.Suspense>
    );
  }
}
