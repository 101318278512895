import { isAdmin } from 'helpers/function'

export const menu = [
  {
    name: 'Accueil',
    icon: 'home',
    route: '/home',
    role: ['ADMIN', 'FLORIST']
  },
  {
    name: isAdmin() ? 'Commandes' : 'Mes commandes',
    icon: 'commands',
    route: '/commands',
    role: ['ADMIN', 'FLORIST']
  },
  {
    name: 'Ajouter produit',
    icon: 'products',
    route: '/add-product',
    role: ['ADMIN']
  },
  {
    name: 'Catalogue',
    icon: 'products',
    route: '/products',
    role: ['FLORIST', 'ADMIN']
  },
  {
    name: 'Produits en vente',
    icon: 'products',
    route: '/my-products',
    role: ['FLORIST']
  },
  {
    name: "Demandes d'ajout de produits",
    icon: 'products',
    route: '/product-requests',
    role: ['ADMIN', 'FLORIST']
  },
  {
    name: 'Catégorie',
    icon: 'icon-profile',
    route: '/category',
    role: ['ADMIN']
  },
  {
    name: 'Sous catégorie',
    icon: 'icon-profile',
    route: '/subcategory',
    role: ['ADMIN']
  },
  {
    name: 'Occasion',
    icon: 'icon-profile',
    route: '/occasion',
    role: ['ADMIN']
  },
  {
    name: 'Profile',
    icon: 'icon-profile',
    route: '/profile',
    role: ['FLORIST']
  },

  {
    name: 'Mon compte',
    icon: 'icon-profile',
    route: '/account',
    role: ['ADMIN', 'FLORIST']
  },
  {
    name: 'Galerie',
    icon: 'gallery',
    route: '/gallery',
    role: ['FLORIST']
  },
  {
    name: 'Tchat',
    icon: 'message',
    route: '/tchat',
    role: ['FLORIST']
  },
  {
    name: 'Aide',
    icon: 'help',
    route: '/help',
    role: ['ADMIN', 'FLORIST']
  }
]
