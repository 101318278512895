import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
const FlexContainer = props => {
	const {
		row,
		column,
		justityBetween,
		justityAround,
		justifyStart,
		justifyEnd,
		justifyCenter,
		alignCenter,
		alignStart,
		alignEnd,
		wrap,
		className,
		style
	} = props;
	const classe = classNames("d-flex",
		row && 'd-flex-row',
		column && 'flex-column',
		justityBetween && 'justify-content-between',
		justityAround && 'justify-content-around',
		justifyStart && 'justify-content-start',
		justifyEnd && 'justify-content-end',
		justifyCenter && 'justify-content-center',
		alignCenter && 'align-items-center',
		alignStart && 'align-items-start',
		alignEnd && 'align-items-end',
		wrap && 'flex-wrap',
		className

	);
	return <div className={classe} style={style} >{props.children}</div>;
};
FlexContainer.propTypes = {
	row: PropTypes.bool,
	column: PropTypes.bool,
	justityBetween: PropTypes.bool,
	justityAround: PropTypes.bool,
	justifyStart: PropTypes.bool,
	justifyEnd: PropTypes.bool,
	alignCenter: PropTypes.bool,
	alignStart: PropTypes.bool,
	alignEnd: PropTypes.bool,
	wrap: PropTypes.bool,
	justifyCenter: PropTypes.bool,
	className: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.any
};

export default FlexContainer;
