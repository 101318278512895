import React from 'react';
import PropTypes from 'prop-types';
import { FlexContainer, Button } from 'components';
const modal = props => {
	const { isOpen, children } = props;

	return (
		isOpen && (
			<div>
				<div
					className="modal-wrapper"
					style={{
						transform: isOpen ? 'translateY(0vh)' : 'translateY(-100vh)',
						opacity: isOpen ? '1' : '0'
					}}
				>
					{children}
				</div>
			</div>
		)
	);
};
modal.propTypes = {
	isOpen: PropTypes.bool,
	children: PropTypes.any
};
export default modal;
