import React from "react";
import PropTypes from "prop-types";
import confirmationJson from "label/confirmationModalLabel";
import { FlexContainer, Button } from "components";
import ModalLayout from "./ModalLayout"
const modal = props => {
    const { isOpen, close, theme, confirm } = props;
    const label = confirmationJson[theme];
    return (
        <ModalLayout isOpen={isOpen} >
            <div>
                <span className="close-modal-btn" onClick={close}>
                    ×
          </span>
            </div>
            <div className="modal-header">
                <h4>{label && label.title}</h4>
            </div>
            <div className="modal-body">
                <p className="label label-grey">{label && label.message}</p>
                <FlexContainer row justifyCenter>
                    <Button cancel title="Annuler" onClick={close} />
                    <Button success title="Valider" onClick={confirm} />
                </FlexContainer>
            </div>
        </ModalLayout >

    );
};
modal.propTypes = {
    isOpen: PropTypes.bool,
    theme: PropTypes.string,
    close: PropTypes.func,
    confirm: PropTypes.func
};
export default modal;
