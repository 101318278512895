import Token from "./Token";
import lodash from "lodash"
export const stripTypenames = value => {
    if (Array.isArray(value)) {
        return value.map(stripTypenames);
    } else if (value !== null && typeof value === 'object') {
        const newObject = {};
        for (const property in value) {
            if (property !== '__typename') {
                newObject[property] = stripTypenames(value[property]);
            }
        }
        return newObject;
    } else {
        return value;
    }
};
export const makeId = (length) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export const removeFirstAndLast = (str) => {
    if (str && str.length > 0) {
        const tab = str.split('');
        const n = tab.filter(c => c !== `"`);
        return n.join('');
    }
    return ''

}
export const isAdmin = () => Token.getRole() === "ADMIN"
export const formatToMultiSelectData=(data)=>{
    let array=[];
    if(data && data.length > 0){
        data.map(category=>{
            if(category.status && category?.subCategories?.length> 0){
                category.subCategories.forEach(subcat=>{
                    array.push({"key":subcat.name,cat:category?.name,subCategoryId:subcat.id,categoryId:category?.id})
                })
            }
        })
    }
    return array;
}
export const reverseSubCategoryToCategory=(data)=>{
  
    let parent=data && data.map(cat=>cat.category);
    parent =lodash.uniqBy(parent, 'id');
    

    let cats = parent.map(cat => {
        let list_sub_categories = [];
        data.map(sub => {
            if (sub?.category?.id === cat.id) {
                list_sub_categories.push(sub);
            }
        });
        return { id: cat.id, name: cat.name, subCategories: list_sub_categories };
    });
    return cats;
    


}