import gql from "graphql-tag";



export const UPDATE_MY_PROFILE =gql`
mutation updateMyStore($input:StoreInput){
    updateMyStore(input:$input){
        id
    }
}
`

export const MY_PROFILe =gql`
query {
    myStore {
        id
        name
        gerantName
        gerantEmail
        gerantTel
        webSite
        address
        tel
        siret
        description
        photoUrl
        closedDay{
            key
            startDay
            endDay
        }
        planing{
            monday{
                firstSession{
                    openAt
                    closeAt
                    work
                }
                secondSession{
                    openAt
                    closeAt
                    work
                }
            }
            tuesday{
                firstSession{
                    openAt
                    closeAt
                    work
                }
                secondSession{
                    openAt
                    closeAt
                    work
                }
            }
            wednesday{
                firstSession{
                    openAt
                    closeAt
                    work
                }
                secondSession{
                    openAt
                    closeAt
                    work
                }
            }
            thursday{
                firstSession{
                    openAt
                    closeAt
                    work
                }
                secondSession{
                    openAt
                    closeAt
                    work
                }
            }
            friday{
                firstSession{
                    openAt
                    closeAt
                    work
                }
                secondSession{
                    openAt
                    closeAt
                    work
                }
            }
            saturday{
                firstSession{
                    openAt
                    closeAt
                    work
                }
                secondSession{
                    openAt
                    closeAt
                    work
                }
            }
            sunday{
                firstSession{
                    openAt
                    closeAt
                    work
                }
                secondSession{
                    openAt
                    closeAt
                    work
                }
            }
        }
        location{
            latitude
            longitude
        }
        profile{
            email
            
        }
    }
}
`