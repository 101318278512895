import React from 'react';
import PropTypes from 'prop-types';
const CustomCheckbox = ({ name, label, onChange, disabled, style, isChecked, readOnly }) => {
  return (
    <div className="BtnCheck">
      <label style={style} className="labelClassname">
        <input
          type="checkbox"
          checked={isChecked}
          name={name}
          onChange={onChange}
          className="checkbox-input hidden"
          disabled={disabled}
          readOnly={readOnly}

        />
        <span className='label-text'>{label}</span>
      </label>
    </div>
  );
};
CustomCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  isChecked: PropTypes.bool,
  readOnly: PropTypes.bool
};
export default CustomCheckbox;
