import React, { Component } from "react";
import { Form, FormGroup, Image } from "react-bootstrap";
import { Input, Button } from "components";
import { withRouter } from "react-router";
import { BASE_URL } from "config/constants";
import axios from "config/axios";
import { Routes } from "helpers/Contants";
class LoginForm extends Component {
  state = {
    loading: false,
    error: null,
    email: "admin@gmail.com",
    password: "123456"
  };
  handleSubmit = () => {
    this.setState({ loading: true, error: null });
    const data = {
      email: this.state.email.trim(),
      password: this.state.password
    };
    axios
      .post("auth/login", data)
      .then(response => {
        const { success, token, fieldError, id } = response.data;
        if (success) {
          localStorage.setItem("token", token);
          localStorage.setItem("id-user", id);
          this.props.history.push(Routes.home);
        } else {
          this.setState({ error: fieldError });
        }
        this.setState({ loading: false });
      })
      .catch(err => console.log(err));
  };
  render() {
    return (
      <div className="login-panel">
        <div className="form-panel">
          <div className="form-panel-header pl-4">
            <Image className="logo" src="images/login/logo.png" />
          </div>
          <div className="form-panel-body">
            <Form className="flex flex-column justify-content-around align-items-center">
              <span className="title">Bienvenue</span>
              <FormGroup>
                <div className="flex border-bottom align-items-center">
                  <Image src="/images/login/user.png" className="icon" />
                  <Input
                    value={this.state.email}
                    placeholder="email@email.com"
                    className="ml-2"
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="flex border-bottom align-items-center">
                  <Image src="/images/login/padlock.png" className="icon" />
                  <Input
                    value={this.state.password}
                    type="password"
                    placeholder="*******"
                    className="ml-2"
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  title="Se connecter"
                  className="login-btn"
                  onClick={this.handleSubmit}
                  loading={this.state.loading}
                />
              </FormGroup>
              {this.state.error && (
                <span className="label label-error">{this.state.error}</span>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(LoginForm);
