import React from "react";
import { Checkbox } from "components";
import { Table, CustomInput } from "reactstrap";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-apollo";
import { SET_PRODUCT_TO_ADD } from "graphql/mutation.local";
import { LOAD_PRODUCTS_TO_ADD } from "graphql/query.local";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Routes } from "helpers/Contants";
import withConfirmation from "hoc/withConfirmation";
import Tags from "./Tags"
import {
  useMutationRemoveProduct,
  useMutationEnableProduct,
  useLocalQueryFilterProduct
} from "hooks/product/product.hooks";
import {
  makeId,
  isAdmin,
  reverseSubCategoryToCategory
} from "helpers/function";
import { usePaginationMutation } from "hooks/pagination/pagination.hooks";
import { Store, Admin } from "components/common/Admin";


const Row = ({
  photoUrls,
  name,
  id,
  createdBy,
  isChecked,
  localProducts,
  subCategories,
  refProduct,
  myProducts,
  enabled,
  active,
  setProduct,
  setPaginationHooks,
  removeProduct,
  enableThisProduct,
  confirmationDelete,
  history
}) => {

  let tags = reverseSubCategoryToCategory(subCategories).length
    ? reverseSubCategoryToCategory(subCategories)
    : [];
  function AddProduct() {
    if (isChecked === false || !!localProducts.includes(id)) {
      setProduct({
        variables: {
          id
        }
      });
    }
  }
  function enableProduct() {
    enableThisProduct(id);
  }
  function navigate() {
    history.push(`${Routes.detailProduct}/${id}`);
  }
  function removeFomrMyProduct() {
    confirmationDelete("removeProduct", () => {
      removeProduct(id);
      setPaginationHooks({ name: "skip", value: 0 });
    });
  }

  return (
    <tr>
      <Store>
        <td style={{ fontSize: "20px" }}>
          {!!myProducts ? (
            <Checkbox isChecked={isChecked} onChange={removeFomrMyProduct} />
          ) : (
            <Checkbox isChecked={isChecked} onChange={AddProduct} />
          )}
        </td>
      </Store>

      <td>
        <div className="boxGalCercle" onClick={navigate}>
          <img
            src={photoUrls && photoUrls[0] && photoUrls[0].photoUrl}
            alt="product-image"
          />
        </div>
      </td>
      <th scope="row">
        <div onClick={navigate} className="cursor-pointer title-small-px">
          {refProduct}
        </div>
      </th>
      <td className="title-small-px">{name}</td>
      <td>
        <Tags data={tags} />
      </td>
      <Admin>
        <td>
          <span className="title-small-px"> {createdBy?.florist?.name}</span>
        </td>
      </Admin>
      <Store>
        <td />
      </Store>

      {myProducts && (
        <td>
          <CustomInput
            type="switch"
            name="customSwitch"
            id={id}
            checked={enabled}
            onChange={enableProduct}
          />
        </td>
      )}
      <Admin>
        <td>
          <CustomInput
            type="switch"
            name="customSwitch"
            id={id}
            checked={active}
            onChange={enableProduct}
          />
        </td>
      </Admin>
    </tr>
  );
};
Row.propTypes = {
  photoUrls: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  createdBy: PropTypes.object,
  isChecked: PropTypes.bool,
  subCategories: PropTypes.array,
  localProducts: PropTypes.array,
  myProducts: PropTypes.bool,
  refProduct: PropTypes.string,
  confirmationDelete: PropTypes.func,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  setProduct: PropTypes.func,
  setPaginationHooks: PropTypes.func,
  removeProduct: PropTypes.func,
  enableThisProduct: PropTypes.func,
  history:PropTypes.object
};

const ProductTableResponsive = ({ data, myProducts, confirmationDelete }) => {
  const {
    productFilter: { myProducts: adminStore }
  } = useLocalQueryFilterProduct();
  const [setProduct] = useMutation(SET_PRODUCT_TO_ADD);
  const { removeProduct } = useMutationRemoveProduct();
  const { setPaginationHooks } = usePaginationMutation();
  const { enableThisProduct } = useMutationEnableProduct();
  const history=useHistory();
  let COLUMNS = [
    "",
    "Photo",
    "Référence",
    "Nom",
    "Categorie",
    isAdmin() && adminStore && "Fleuriste",
    ""
  ];
  isAdmin() && COLUMNS.shift();
  const {
    data: {
      productsChecked: { products }
    }
  } = useQuery(LOAD_PRODUCTS_TO_ADD);
  const tempData = [];
  data &&
    data.forEach(prod =>
      products.includes(prod && prod.id)
        ? tempData.push({ ...prod, isChecked: true })
        : tempData.push(prod)
    );
  const renderHeader = () => {
    return (
      <thead>
        <tr>
          {COLUMNS.map(col => (
            <th key={makeId(5)}>{col}</th>
          ))}
        </tr>
      </thead>
    );
  };
  return (
    <Table className="mt-4 ProductTable" hover responsive>
      {renderHeader()}
      <tbody>
        {tempData &&
          tempData.map(product => (
            <Row
              key={product.id}
              {...product}
              localProducts={products}
              myProducts={myProducts}
              confirmationDelete={confirmationDelete}
              setProduct={setProduct}
              removeProduct={removeProduct}
              setPaginationHooks={setPaginationHooks}
              enableThisProduct={enableThisProduct}
              history={history}
            />
          ))}
      </tbody>
    </Table>
  );
};
ProductTableResponsive.propTypes = {
  data: PropTypes.array,
  myProducts: PropTypes.bool,
  confirmationDelete: PropTypes.func
};
export default withConfirmation(ProductTableResponsive);
