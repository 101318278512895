import gql from 'graphql-tag';

export const ADD_CATEGORY_MUTATION = gql`
	mutation addCategory($input: CategoryInput) {
		addCategory(input: $input) {
			id
			name
			photoUrl
            status
		}
	}
`;


export const ADD_SUB_CATEGORY_MUTATION = gql`
	mutation addSubCategory($input: SubCategoryInput) {
		addSubCategory(input: $input) {
			id
			name
			category{
				id
				name
			}
            status
		}
	}
`;

export const UPDATE_CATEGORY_MUTATION = gql`
	mutation updateCategory($input: CategoryInput) {
		updateCategory(input: $input) {
			id
			name
			photoUrl
            status
		}
	}
`;
export const UPDATE_SUB_CATEGORY_MUTATION = gql`
	mutation updateSubCategory($input: SubCategoryInput) {
		updateSubCategory(input: $input) {
			id
			name
			category{
				id
				name
			}
            status
		}
	}
`;
export const GET_ALL_CATEGORY = gql`
	query {
		getCategories {
			id
			name
            status
			photoUrl
		}
	}
`;
export const GET_ALL_CATEGORY_WITH_SUB_CATEGORIES = gql`
	query {
		getCategories {
			id
			name
			status
			subCategories{
				id
				name
				status
			}

		}
	}
`;
export const GET_ALL_SUB_CATEGORY = gql`
	query getSubCategories($filter:SubCategoryFilter){
		getSubCategories(filter:$filter){
			id
			name
            status
			category{
				id
				name
			}
		}
	}
`;
export const DELETE_CATEGORY_MUTATION = gql`
mutation deleteCategory($id: ID) {
    deleteCategory(id: $id) {
        id
        name
        photoUrl
    }
}
`;
export const DELETE_SUB_CATEGORY_MUTATION = gql`
mutation deleteSubCategory($id: ID) {
    deleteSubCategory(id: $id) {
        id
    
    }
}
`;
export const ENABLE_CATEGORY_MUTATION = gql`
mutation enableCategory($id: ID!) {
    enableCategory(id: $id){
		id
	}
}
`;
export const ENABLE_SUB_CATEGORY_MUTATION = gql`
mutation enableSubCategory($id: ID!) {
    enableSubCategory(id: $id){
		id
	}
}
`;
