import { useQuery, useMutation } from "react-apollo";
import { LOAD_PAGINATION_INFO } from "graphql/query.local";
import { SET_PAGINATION } from "graphql/mutation.local";

export const usePaginationMutation = () => {
    const { data: { loadPagination: { skip, limit } } } = useQuery(LOAD_PAGINATION_INFO);
    const [setPagination] = useMutation(SET_PAGINATION);
    function setPaginationHooks({ name, value }) {
        setPagination({
            variables: {
                name,
                value
            }
        })
    }
    return { setPaginationHooks, skip, limit };
}