import { LOAD_PRODUCTS_TO_ADD, LOAD_PRODUCT_FILTER } from "graphql/query.local";
import { useQuery, useMutation } from "react-apollo";
import { ADD_PRODUCT_TO_MY_PRODUCTS, REMOVE_PRODUCT_FROM_MY_PRODUCTS, GET_ALL_PRODUCTS, ENABLE_PRODUCT, LOAD_PRODUCT_BY_ID, UPDATE_PRODUCT_MUTATION } from "graphql/tags";
import { usePaginationMutation } from "hooks/pagination/pagination.hooks";
import { SET_PRODUCT_FILTER_CATALOG, SET_PRODUCT_FILTER_CATAEGORY, SET_PRODUCT_TO_ADD } from "graphql/mutation.local";
import { HANDLE_REQUEST } from "graphql/tags/requests.tag";
import { useRefuseQuery } from "hooks/request/request.hooks";
import { isAdmin } from "helpers/function";

export const useQueryProductToAdd = () => {
    const [addProductToFlorist] = useMutation(ADD_PRODUCT_TO_MY_PRODUCTS);
    const { data: { productsChecked: { products } } } = useQuery(LOAD_PRODUCTS_TO_ADD);
    return {
        products,
        addProductToFlorist

    }
}

export const useMutationSetProductInCart=()=>{
    const [setProduct] = useMutation(SET_PRODUCT_TO_ADD);
    function selectProduct(id){
        setProduct({
            variables: {
              id
            }
          });
    }
    return {selectProduct};
}

export const useProductQuery = (productId) => {
    const { data, loading } = useQuery(LOAD_PRODUCT_BY_ID, {
        variables: {
            productId
        }
    })
    return { product: data && data.getProductById, loading };
}
export const useMutationEnableProduct = () => {
    const [enableProduct, { error }] = useMutation(ENABLE_PRODUCT);
    const { limit, skip } = usePaginationMutation();
    const { productFilter: { myProducts,category } } = useLocalQueryFilterProduct();
    function enableThisProduct(id) {
        
        enableProduct({
            variables: {
                id
            },

            /*update: (cache, { }, _) => {

                const variables = isAdmin() ? { skip, limit,filter:{storeCatalog:myProducts,category} } : { skip, limit, filter: { myProducts: true,category } };
                let datax = cache.readQuery({
                    query: GET_ALL_PRODUCTS, variables
                })

                datax = {
                    ...datax,
                    getProducts: {
                        ...datax.getProducts,
                        nodes: datax.getProducts.nodes.map(prod => {

                            if (isAdmin()) {
                                if (prod.id == id) {
                                    prod.active = !!!prod.active
                                }
                                return prod;
                            }
                            if (prod.id == id) {
                                prod.enabled = !!!prod.enabled
                            }
                            return prod;
                        })
                    }
                }


                cache.writeQuery({ query: GET_ALL_PRODUCTS, variables, data: datax })
            }*/
        })
    }
    return {
        enableThisProduct
    }
}
export const useMutationRemoveProduct = () => {
    const [removeProductToFlorist] = useMutation(REMOVE_PRODUCT_FROM_MY_PRODUCTS);
    const { limit, skip } = usePaginationMutation();
    function removeProduct(id) {
        return removeProductToFlorist({
            variables: {
                id
            },
            update: (cache, { }) => {
                let datax = cache.readQuery({
                    query: GET_ALL_PRODUCTS, variables: {
                        skip, limit, filter: {
                            myProducts: true
                        }
                    }
                })
                datax = {
                    ...datax,
                    getProducts: {
                        ...datax.getProducts,
                        nodes: datax.getProducts.nodes.filter(prod => prod.id !== id)
                    }
                }

                cache.writeQuery({ query: GET_ALL_PRODUCTS, variables: { skip, limit, filter: { myProducts: true } }, data: datax })

            }
        })
    }
    return { removeProduct }
}
export const useLocalQueryFilterProduct = () => {
    const { data } = useQuery(LOAD_PRODUCT_FILTER);
    return { productFilter: data.productFilter };
}
export const useQueryProducts = () => {
    const { skip, limit } = usePaginationMutation();
    const { productFilter: { myProducts,category } } = useLocalQueryFilterProduct();
    console.log(myProducts,category);
    const { data, loading } = useQuery(GET_ALL_PRODUCTS, {
        variables: {
            limit,
            skip,
            filter: {
                storeCatalog: myProducts,
                category
              
            }
        },
        fetchPolicy: "cache-first"
    });
    return {
        data, loading
    }
}
export const useMutationFilterProduct = () => {
    const [setProductFilter] = useMutation(SET_PRODUCT_FILTER_CATALOG);
    const { setPaginationHooks } = usePaginationMutation();
    function setProductFilterCatalog(myProducts) {

        setProductFilter({
            variables: {
                myProducts
            }
        })
        setPaginationHooks({ name: "skip", value: 0 })
    }
    return {
        setProductFilterCatalog
    }
}
export const useProductMutationHandleRequest = () => {
    const [handleRequest] = useMutation(HANDLE_REQUEST);
    const { text } = useRefuseQuery();
    function _handleRequest({ input, status }) {
        return new Promise((resolve, reject) => {
            handleRequest({
                variables: {
                    input: {
                        ...input, refuseReason: text
                    },
                    status
                }
            }).then(res => resolve(res)).catch(err => reject(err))
        })

    }
    return { _handleRequest }
}
export const useMutationFilter=()=>{
    const[setProductFilterCategory]=useMutation(SET_PRODUCT_FILTER_CATAEGORY);
    function setFilterProduct({input}){
        setProductFilterCategory({
            variables:{
                input
            }
        })
  
    }
    return {setFilterProduct}

}

export const useMutationUpdateProduct=()=>{
    const [updateProduct]=useMutation(UPDATE_PRODUCT_MUTATION);
    function updtateThisProduct({input}){
        console.log(input);
        updateProduct({
            variables:{
                input
            }
        })
    }
    return {updtateThisProduct}
}