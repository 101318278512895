import { useMutation, useQuery } from "react-apollo";
import {
  GET_ALL_CATEGORY,
  ENABLE_CATEGORY_MUTATION,
  GET_ALL_SUB_CATEGORY,
  ADD_SUB_CATEGORY_MUTATION,
  DELETE_SUB_CATEGORY_MUTATION,
  UPDATE_SUB_CATEGORY_MUTATION,
  ENABLE_SUB_CATEGORY_MUTATION,
  GET_ALL_CATEGORY_WITH_SUB_CATEGORIES
} from "graphql/tags/category.tag";

export const useMutationEnableCategory = () => {
  const [enableTheme] = useMutation(ENABLE_CATEGORY_MUTATION);
  function enableCategoryMutation(id) {
    enableTheme({
      variables: {
        id
      },
      update: (cache, { data }) => {
        console.log(data);
        let datax = cache.readQuery({ query: GET_ALL_CATEGORY });

        datax = {
          ...datax,
          getCategories: [
            ...datax.getCategories.map(cat => {
              if (cat.id === id) {
                cat.status = !!!cat.status;
                return cat;
              }
              return cat;
            })
          ]
        };

        cache.writeQuery({ query: GET_ALL_CATEGORY, data: datax });
      }
    });
  }
  return { enableCategoryMutation };
};

export const useQuerySubCategory = () => {
  const { data, loading } = useQuery(GET_ALL_SUB_CATEGORY);
  console.log("hooks", data);
  return {
    data: data && data.getSubCategories ? data.getSubCategories : null,
    loading
  };
};
export const useMutationSubCategory = () => {
  const [addSubCategory] = useMutation(ADD_SUB_CATEGORY_MUTATION);
  function addSubCategoryMutation({ input }) {
    addSubCategory({
      variables: {
        input
      },
      update: (cache, { data: { addSubCategory } }) => {
        let data = cache.readQuery({ query: GET_ALL_SUB_CATEGORY });
        data = {
          ...data,
          getSubCategories: [...data.getSubCategories, addSubCategory]
        };
        cache.writeQuery({ query: GET_ALL_SUB_CATEGORY, data });
      }
    });
  }
  return { addSubCategoryMutation };
};
export const useMutationDeleteSubCategory = () => {
  const [deleteSubCategory] = useMutation(DELETE_SUB_CATEGORY_MUTATION);
  function deleteSubCategoryMutation(id) {
    deleteSubCategory({
      variables: {
        id
      },
      update: (cache, {}) => {
        let data = cache.readQuery({ query: GET_ALL_SUB_CATEGORY });
        data = {
          ...data,
          getSubCategories: [
            ...data.getSubCategories.filter(cat => cat?.id !== id)
          ]
        };
        cache.writeQuery({ query: GET_ALL_SUB_CATEGORY, data });
      }
    });
  }
  return { deleteSubCategoryMutation };
};
export const useMutationUpdateSubCategory = () => {
  const [updateSubCategory] = useMutation(UPDATE_SUB_CATEGORY_MUTATION);
  function updateSubCategoryMutation({ input }) {
    updateSubCategory({
      variables: {
        input
      },
      update: (cache, { data: { updateSubCategory } }) => {
        let data = cache.readQuery({ query: GET_ALL_SUB_CATEGORY });

        data = {
          ...data,
          getSubCategories: [
            ...data.getSubCategories.map(cat =>
              cat.id == updateSubCategory.id ? updateSubCategory : cat
            )
          ]
        };
        cache.writeQuery({ query: GET_ALL_SUB_CATEGORY, data });
      }
    });
  }
  return { updateSubCategoryMutation };
};

export const useMutationEnableSubCategory = () => {
    const [enableSubCategory] = useMutation(ENABLE_SUB_CATEGORY_MUTATION);
    function enableSubCategoryMutation(id) {
        enableSubCategory({
        variables: {
          id
        },
        update: (cache, { data }) => {
         
          let datax = cache.readQuery({ query: GET_ALL_SUB_CATEGORY });
            console.log("datax",datax)
          datax = {
            ...datax,
            getSubCategories: [
              ...datax.getSubCategories.map(cat => {
                if (cat.id === id) {
                  cat.status = !!!cat.status;
                  return cat;
                }
                return cat;
              })
            ]
          };
  
          cache.writeQuery({ query: GET_ALL_SUB_CATEGORY, data: datax });
        }
      });
    }
    return { enableSubCategoryMutation };
  };
  export const useQueryCategoryWihSubCategories=()=>{
      const{data,loading}=useQuery(GET_ALL_CATEGORY_WITH_SUB_CATEGORIES);
      
      return {
          data:data?.getCategories,
          loading
      }
  }
  export const useQueryCategory=()=>{
    const {data,loading}=useQuery(GET_ALL_CATEGORY);
    return {
      data:data?.getCategories
    }
  }